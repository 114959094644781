import React from "react";
import CardItem from "./CardItem";
import "../css/ListOfCards.scss";
import { useSelector } from "react-redux";

export default function ListOfCards(props) {
  const appReducer = useSelector((state) => state.appReducer);
  const listOfCards = props.listdata.map((data, idxGroup) => (
    <div
      key={idxGroup}
      className={
        appReducer.isMobile ? "home-content-card-mobile" : "home-content-card"
      }
    >
      {data.map((image, idxImage) => (
        <CardItem
          key={idxImage}
          image={image.url}
          wording={image.wording}
          describe={image.describe}
          path={image.path}
          hasButton={props.hasButton}
          hasContent={props.hasContent}
        />
      ))}
    </div>
  ));
  return <div>{listOfCards}</div>;
}
