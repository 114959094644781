import { useState } from "react";
import "../css/NavItems.scss";
import { Link, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import navbarReducer from "../../../reducers/navbar.reducer";
import * as navbarAction from "../../../actions/navbar.action";

function NavItems(props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const hasPath = props.goTo !== "";

  return (
    <li
      className="bbn-nav-items"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {hasPath ? (
        <Link
          to={props.goTo}
          onClick={() => dispatch(navbarAction.handleClick())}
        >
          {props.wording}
        </Link>
      ) : (
        <span> {props.wording}</span>
      )}

      {open && props.children}
    </li>
  );
}

export default NavItems;
