export const productElectronics = [
  {
    id: 1,
    url: "/images/pages/product-electronics/power-module-1.jpg",
    wording: "Power Module",
    describe: "SEMIKRON-SKM100GB128D",
    path: "/contact-us",
  },
  {
    id: 2,
    url: "/images/pages/product-electronics/power-module-2.jpg",
    wording: "Power Module",
    describe: "SEMIKRON-SKM300GB126D",
    path: "/contact-us",
  },
  {
    id: 3,
    url: "/images/pages/product-electronics/power-diode-1.jpg",
    wording: "Power Diode",
    describe: "Sanrex-DF100AA160",
    path: "/contact-us",
  },
  {
    id: 4,
    url: "/images/pages/product-electronics/power-diode-2.jpg",
    wording: "Power Diode",
    describe: "Sanrex-DF30AA160",
    path: "/contact-us",
  },
  {
    id: 5,
    url: "/images/pages/product-electronics/power-diode-3.jpg",
    wording: "Power Diode",
    describe: "Sanrex-DF150AA160",
    path: "/contact-us",
  },
  {
    id: 6,
    url: "/images/pages/product-electronics/fuse-1.jpg",
    wording: "Fuse",
    describe: "BUSSMANN-45FE",
    path: "/contact-us",
  },
  {
    id: 7,
    url: "/images/pages/product-electronics/fuse-2.jpg",
    wording: "Fuse",
    describe: "BUSSMANN-80FE",
    path: "/contact-us",
  },
  {
    id: 8,
    url: "/images/pages/product-electronics/fuse-3.jpg",
    wording: "Fuse",
    describe: "BUSSMANN-120FE",
    path: "/contact-us",
  },
  {
    id: 9,
    url: "/images/pages/product-electronics/module-1.jpg",
    wording: "Module",
    describe: "Mitsubishi-CM50MXB2-24A",
    path: "/contact-us",
  },
  {
    id: 10,
    url: "/images/pages/product-electronics/module-2.jpg",
    wording: "Module",
    describe: "Macmic-MT100DT16L1",
    path: "/contact-us",
  },
  {
    id: 11,
    url: "/images/pages/product-electronics/module-3.jpg",
    wording: "Module",
    describe: "Fuji-7MBI50N-120",
    path: "/contact-us",
  },
  {
    id: 12,
    url: "/images/pages/product-electronics/module-4.jpg",
    wording: "Module",
    describe: "IXYS-DSEI2X101-06A",
    path: "/contact-us",
  },
  {
    id: 13,
    url: "/images/pages/product-electronics/module-5.jpg",
    wording: "Module",
    describe: "7MBI50N-120",
    path: "/contact-us",
  },
  {
    id: 14,
    url: "/images/pages/product-electronics/power-module-3.jpg",
    wording: "Power Module",
    describe: "Infineon-FF300R12KS4",
    path: "/contact-us",
  },
  {
    id: 15,
    url: "/images/pages/product-electronics/temperature-controller.jpg",
    wording: "Temperature Controller",
    describe: "48x48x110mm.  4-20mA Analog Output",
    path: "/contact-us",
  },
];
