import React from "react";
import SubmitForm from "../../fragments/js/SubmitForm";
import "../css/ContactUs.scss";
import HomeIcon from "@mui/icons-material/Home";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";

export default function ContactUs() {
  return (
    <div className="contact-us-body">
      <div className="contact-us-header-top">
        <div className="bbn-logo-slogan">
          <img src="/images/logo/bbn-logo.png" className="bbn-logo" />
          <div className="bbn-slogan">รับจัดทำเครื่องตามสเปคการใช้งาน</div>
        </div>
        <div className="header-detail">
          <div className="header-detail-left">
            <h1 className="primary-size">ติดต่อเรา</h1>
            <h1 className="primary-size">
              บริษัท บีบีเอ็น เอ็นจิเนียริ่ง จำกัด
            </h1>
            <div className="secondary-size">
              <span className="head-secondary-size">
                <HomeIcon fontSize="small" />
              </span>
              &nbsp;: 39/46 ซอยเพชรเกษม 110 แยก 14-1-1-2 <br /> แขวงหนองค้างพลู
              เขตหนองแขม กรุงเทพฯ 10160
            </div>
            <div className="secondary-size">
              <span className="head-secondary-size">
                <ContactPhoneIcon fontSize="small" />
              </span>
              &nbsp;: 02-814-3395
            </div>
            <div className="secondary-size">
              <span className="head-secondary-size">
                <PhoneIcon fontSize="small" />
              </span>
              &nbsp;: 081-811-8923, 092-276-4619
            </div>
            <div className="secondary-size">
              <span className="head-secondary-size">
                <EmailIcon fontSize="small" />
              </span>
              &nbsp;: bbn.eng1979@gmail.com
            </div>
            <div className="secondary-size">
              <span className="head-secondary-size">
                <LanguageIcon fontSize="small" />
              </span>
              &nbsp;: www.b-heat.com
            </div>
          </div>
          <div className="header-detail-right">
            <img
              src="https://qr-official.line.me/sid/M/041ykdwm.png"
              alt=""
              className="social-logo"
            />
            <a className="social-contact" href="https://lin.ee/EawBh4h">
              <img
                src="/images/logo/line-2-logo.png"
                alt="เพิ่มเพื่อน"
                className="social-logo"
              />
            </a>
            <a
              className="social-contact"
              href="https://www.facebook.com/BBN.Engineering"
            >
              <img
                src="/images/logo/facebook-2-logo.png"
                alt="เข้าชม Facebook"
                className="social-logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="contact-us-submit-form">
        <SubmitForm />
      </div>
    </div>
  );
}
