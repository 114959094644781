import {
  APP_ACTION_RESCREEN,
  APP_ACTION_MODAL_TOGGLE,
  APP_ACTION_LOADING,
} from "../Constant";

const initialState = {
  isMobile: window.innerWidth < 780 ? true : false,
  isShowModal: false,
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case APP_ACTION_RESCREEN: {
      return { ...state, isMobile: window.innerWidth < 780 ? true : false };
    }

    case APP_ACTION_MODAL_TOGGLE: {
      return { ...state, isShowModal: payload };
    }

    case APP_ACTION_LOADING: {
      return { ...state, isLoading: payload };
    }

    default:
      return state;
  }
};
