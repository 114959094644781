import React from "react";
import "../css/ServicePage.scss";
import {
  machineDesign,
  electronicDesign,
  coilDesign,
} from "../../../data/product-service.model";
import ListOfCards from "../../fragments/js/ListOfCards";
import Footer from "../../fragments/js/Footer";

export default function ServicePage() {
  let listOfMachineImage = [machineDesign];
  let listOfElectronicImage = [electronicDesign];
  let listOfCoilImage = [coilDesign];
  return (
    <div className="service-body">
      <div className="service-content">
        <div className="service-content-header">
          <h1>งานออกแบบเครื่องจักร</h1>
          <ul>
            <li>ออกแบบตรงตามการใช้งานของลูกค้า </li>
            <li>ออกแบบเครื่องจักร</li>
          </ul>
        </div>
        <div className="service-content-images">
          <ListOfCards listdata={listOfMachineImage} />
        </div>
      </div>
      <div className="service-content-dark">
        <div className="service-content-images">
          <ListOfCards listdata={listOfElectronicImage} />
        </div>
        <div className="service-content-header">
          <h1>งานออกแบบระบบไฟฟ้า</h1>
          <ul>
            <li>ออกแบบระบบ PLC</li>
            <li>ออกแบบงานระบบออโตเมชั่น </li>
          </ul>
        </div>
      </div>
      <div className="service-content margin-bottom-decress-15">
        <div className="service-content-header">
          <h1>งานออกแบบ Coil</h1>
          <ul>
            <li>ออกแบบตรงตามการใช้งานของลูกค้า </li>
            <li>ออกแบบโดยอ้างอิงตามทฤษฎีและประสบการณ์</li>
          </ul>
        </div>
        <div className="service-content-images">
          <ListOfCards listdata={listOfCoilImage} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
