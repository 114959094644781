import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import "../css/ListOfCards.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function CardItem(props) {
  const appReducer = useSelector((state) => state.appReducer);
  const location = useLocation();
  const widthOfCard = appReducer.isMobile ? 200 : 350;
  const heightOfImage = appReducer.isMobile ? "120" : "200";
  const fontSize = appReducer.isMobile ? "h6" : "h5";
  const path = props.path != "" ? props.path : location.pathname;
  return (
    <div className="home-card-item">
      <Link to={path}>
        <Card sx={{ width: widthOfCard }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height={heightOfImage}
              image={props.image}
              alt={props.wording}
            />
            {props.hasContent && (
              <CardContent>
                <Typography
                  gutterBottom
                  variant={fontSize}
                  component="div"
                  textAlign="start"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  fontFamily="var(--font-family-bbn)"
                >
                  {props.wording}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="start"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  fontFamily="var(--font-family-bbn)"
                >
                  {props.describe}
                </Typography>
              </CardContent>
            )}
          </CardActionArea>
          {props.hasButton && (
            <CardActions>
              <Button size="large" color="primary">
                ติดต่อเรา
              </Button>
            </CardActions>
          )}
        </Card>
      </Link>
    </div>
  );
}
