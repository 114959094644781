import * as React from "react";
import emailjs from "emailjs-com";
import { useSelector, useDispatch } from "react-redux";
import * as appAction from "../../../actions/app.action";
import TransitionsModal from "./TransitionsModal";

export default function SubmitFrom() {
  const appReducer = useSelector(({ appReducer }) => appReducer);
  const dispatch = useDispatch();
  let title = "สำเร็จ";
  let message = "ส่งอีเมลเรียบร้อย บริษัทจะทำการตอบกลับภายใน 24 ชม.";
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_2yt3af5",
        "template_fi588wb",
        e.target,
        "user_taZWLSJEcTCpqIlQuQsnN"
      )
      .then((res) => {
        dispatch(appAction.setLoadingState(false));
        dispatch(appAction.setToggleModalState(true));
      })
      .catch((err) => {
        title = "ไม่สำเร็จ";
        message = "กรุณาลองใหม่ หรือ ติดต่อช่องทางอื่น";
        dispatch(appAction.setLoadingState(false));
        dispatch(appAction.setToggleModalState(true));
      });
  };
  return (
    <div className="container border submit-container">
      <h1 className="submit-header">แบบฟอร์มติดต่อกลับ</h1>
      <form onSubmit={sendEmail}>
        <label>Name : </label>
        <input
          type="text"
          name="user_name"
          className="form-control margin-bottom-15"
        />

        <label>Email : </label>
        <input
          type="email"
          name="user_email"
          className="form-control margin-bottom-15"
        />

        <label>Message : </label>
        <textarea
          name="user_message"
          rows="4"
          className="form-control margin-bottom-15"
        />
        <input
          type="submit"
          value="Send"
          onClick={() => dispatch(appAction.setLoadingState(true))}
          className="submit-btn form-control btn btn-primary"
        />
      </form>
      <TransitionsModal title={title} message={message} />
    </div>
  );
}
