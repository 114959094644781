import React from "react";
import Footer from "../../fragments/js/Footer";
import "../css/WorkAppliedPage.scss";
import ReactPlayer from "react-player";
import { dataWorkApplied } from "../../../data/work-applied.model";
import { useLocation } from "react-router-dom";

export default function WorkAppliedPage() {
  const location = useLocation();
  const setData = dataWorkApplied.filter(
    (data) => data.path === location.pathname
  );
  return (
    <div className="work-apply-body">
      {setData[0].url == "" ? (
        <div className="content-no-data">
          <div className="content-message">
            <h1> No data.</h1>
            <h2> Coming soon....</h2>
          </div>
          <img src="/images/background/bbn-bg-2.png" />
        </div>
      ) : (
        <div className="work-apply-content">
          <div className="work-apply-content-header">
            <h1>{setData[0].wording}</h1>
            <ul>
              {setData[0].describe.map((data, idx) => (
                <li key={idx}>{data.wording} </li>
              ))}
            </ul>
          </div>
          <div className="work-apply-content-images">
            <ReactPlayer controls url={setData[0].url} />
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}
