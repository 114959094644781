import {
  APP_ACTION_RESCREEN,
  APP_ACTION_MODAL_TOGGLE,
  APP_ACTION_LOADING,
} from "../Constant";

export const setIsMobileState = () => ({
  type: APP_ACTION_RESCREEN,
});

export const setToggleModalState = (payload) => ({
  type: APP_ACTION_MODAL_TOGGLE,
  payload: payload,
});

export const setLoadingState = (payload) => ({
  type: APP_ACTION_LOADING,
  payload: payload,
});

export const checkMobileScreen = () => {
  return (dispatch) => {
    dispatch(setIsMobileState());
  };
};

export const handleToggleModal = (payload) => {
  return (dispatch) => {
    dispatch(setToggleModalState(payload));
  };
};

export const handleLoadingPage = (payload) => {
  return (dispatch) => {
    dispatch(setLoadingState(payload));
  };
};
