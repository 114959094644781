import { NAVBAR_ACTION_MENU_TOGGLE } from "../Constant";

export const setToggleClickState = () => ({
  type: NAVBAR_ACTION_MENU_TOGGLE,
});

export const handleClick = () => {
  return (dispatch) => {
    dispatch(setToggleClickState());
  };
};
