import React from "react";
import "../css/ProductGrid.scss";
import Footer from "../../fragments/js/Footer";
import ListOfCards from "../../fragments/js/ListOfCards";
import { Link, Route } from "react-router-dom";
import { productElectronics } from "../../../data/product-electronics.model";

export default function ProductGrid() {
  const setOfData = productElectronics;
  const row = 2;
  const setImagesWorkApply = (row) => {
    let setItem = [];
    let index = 1;
    setOfData.map((data) => {
      setItem[index] === undefined
        ? (setItem[index] = [data])
        : setItem[index].push(data);
      index = index + 1;
      index = index == row ? 0 : index;
    });
    return setItem;
  };
  const listOfImages = setImagesWorkApply(row);
  console.log(listOfImages);
  return (
    <div className="product-body">
      <div className="product-header-message">อะไหล่อิเล็กทรอนิคส์</div>
      <div className="product-sub-header-message">Spare Parts</div>
      <ListOfCards listdata={listOfImages} hasButton={true} hasContent={true} />
      <div className="product-ads">
        <div className="content">
          <div className="content-header">
            ตู้ MDB และตู้สวิทซ์บอร์ด <br />
            (main distribution board)
          </div>
          <div className="content-body">
            มีบริการจัดทำตู้ MDB และตู้สวิทซ์บอร์ด (main distribution board)
            ออกแบบให้เหมาะกับงานของลูกค้า
          </div>
          <div className="content-button">
            <Link to="/contact-us">ติดต่อเรา</Link>
          </div>
        </div>
        <img src="/images/background/bbn-bg-2.png" />
      </div>
      <Footer />
    </div>
  );
}
