export const imageWorkApply = [
  {
    id: 1,
    url: "images/pages/home/work-apply/melting.jpg",
    wording: "การหลอมโลหะ",
    describe: "การหลอมโลหะ (Induction Melting)",
    path: "/work-applied/melting",
  },
  {
    id: 2,
    url: "images/pages/home/work-apply/hardening.jpg",
    wording: "การชุบแข็งโลหะ",
    describe: "การชุบแข็งโลหะ (Induction Hardening)",
    path: "/work-applied/hardening",
  },
  {
    id: 3,
    url: "images/pages/home/work-apply/annealing.jpg",
    wording: "การอบอ่อนโลหะ",
    describe: "การอบอ่อนโลหะ (Induction Annealing)",
    path: "/work-applied/annealing",
  },
  {
    id: 4,
    url: "images/pages/home/work-apply/carbide.jpg",
    wording: "งานเชื่อมคาร์ไบด์",
    describe: "งานเชื่อมคาร์ไบด์ (Induction Carbide)",
    path: "/work-applied/carbide",
  },
  {
    id: 5,
    url: "images/pages/home/work-apply/extruder.jpg",
    wording: "การหลอมพลาสติก",
    describe: "การหลอมพลาสติก (Induction Extruder)",
    path: "/work-applied/extruder",
  },
  {
    id: 6,
    url: "images/pages/home/work-apply/cap-heating.jpg",
    wording: "งานให้ความร้อนฝาฟอยล์",
    describe: "งานให้ความร้อนฝาฟอยล์ (Induction Cap Heating)",
    path: "/work-applied/cap-heating",
  },
  {
    id: 7,
    url: "images/pages/home/work-apply/shrink-fitting.jpg",
    wording: "การสวมเพลา",
    describe: "การสวมเพลา (Induction Shrink Fitting)",
    path: "/work-applied/shrink-fitting",
  },
  {
    id: 8,
    url: "images/pages/home/work-apply/granulating.jpg",
    wording: "การหยดเม็ดโลหะ",
    describe: "การหยดเม็ดโลหะ (Induction Granulating)",
    path: "/work-applied/granulating",
  },
  {
    id: 9,
    url: "images/pages/home/work-apply/heating-steel-bar.jpg",
    wording: "งานให้ความร้อนเหล็ก",
    describe: "งานให้ความร้อนเหล็ก (Induction Heating Steel Bar)",
    path: "/work-applied/heating-steel",
  },
  {
    id: 10,
    url: "images/pages/home/work-apply/casting.jpg",
    wording: "การหล่อโลหะ",
    describe: "การหล่อโลหะ (Induction Casting)",
    path: "/work-applied/casting",
  },
  {
    id: 11,
    url: "images/pages/home/work-apply/forging.jpg",
    wording: "การขึ้นรูปโลหะ",
    describe: "การขึ้นรูปโลหะ (Induction Forging)",
    path: "/work-applied/forging",
  },
  {
    id: 12,
    url: "images/pages/home/work-apply/auto-parts.jpg",
    wording: "งานให้ความร้อนชิ้นส่วนรถยนต์",
    describe: "งานให้ความร้อนชิ้นส่วนรถยนต์ (Induction Auto-Parts)",
    path: "/work-applied/auto-part",
  },
];

export const imagesSlideBar = [
  {
    id: 1,
    url: "images/pages/home/slide-images/slide-1.jpg",
  },
  {
    id: 2,
    url: "images/pages/home/slide-images/slide-2.jpg",
  },
  {
    id: 3,
    url: "images/pages/home/slide-images/slide-3.jpg",
  },
  {
    id: 4,
    url: "images/pages/home/slide-images/slide-4.jpg",
  },
  {
    id: 5,
    url: "images/pages/home/slide-images/slide-5.jpg",
  },
  {
    id: 6,
    url: "images/pages/home/slide-images/slide-6.jpg",
  },
  {
    id: 7,
    url: "images/pages/home/slide-images/slide-7.jpg",
  },
  {
    id: 8,
    url: "images/pages/home/slide-images/slide-8.jpg",
  },
  {
    id: 9,
    url: "images/pages/home/slide-images/slide-9.jpg",
  },
  {
    id: 10,
    url: "images/pages/home/slide-images/slide-10.jpg",
  },
];
