import "./App.css";
import Navbar from "./components/fragments/js/Navbar";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import PublicRouter from "./PublicRouter";
import CheckMobileScreen from "./components/fragments/js/CheckMobileScreen";
import ScrollToTop from "./components/fragments/js/ScrollToTop";
import Loading from "./components/fragments/js/Loading";

function App() {
  return (
    <div className="App">
      <CheckMobileScreen />
      <Router>
        <ScrollToTop />
        <Navbar />
        <PublicRouter />
      </Router>
    </div>
  );
}

export default App;
