import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as appAction from "../../../actions/app.action";

export default function CheckMobileScreen() {
  const appReducer = useSelector(({ appReducer }) => appReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    const checkedScreen = () => {
      dispatch(appAction.checkMobileScreen());
    };
    window.addEventListener("resize", checkedScreen);
  }, [window.innerWidth]);
  return null;
}
