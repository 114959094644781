export const setDataOfDesktop = [
  { id: 1, header: "หน้าหลัก", path: "/home", details: [] },
  {
    id: 2,
    header: "สินค้า/บริการ",
    path: "",
    details: [
      {
        id: 1,
        message: "จัดจำหน่ายเครื่อง Induction",
        path: "/product/induction",
      },
      {
        id: 2,
        message: "ขายอุปกรณ์ไฟฟ้า/อิเล็คทรอนิคส์",
        path: "/product/electronics",
      },
      { id: 3, message: "งานบริการ", path: "/product/service" },
    ],
  },
  {
    id: 3,
    header: "การประยุกต์ใช้งาน Induction Heat",
    path: "",
    details: [
      { id: 1, message: "งานหลอมโลหะ", path: "/work-applied/melting" },
      { id: 2, message: "งานชุบแข็งโลหะ", path: "/work-applied/hardening" },
      { id: 3, message: "งานอบอ่อนโลหะ", path: "/work-applied/annealing" },
      { id: 4, message: "งานหลอมพลาสติก", path: "/work-applied/extruder" },
      { id: 5, message: "งานสวมเพลา", path: "/work-applied/shrink-fitting" },
      { id: 6, message: "งานหยดเม็ดโลหะ", path: "/work-applied/granulating" },
      { id: 7, message: "งานหล่อโลหะ", path: "/work-applied/casting" },
      { id: 8, message: "งานขึ้นรูปโลหะ", path: "/work-applied/forging" },
      { id: 9, message: "งานเชื่อมคาร์ไบด์", path: "/work-applied/carbide" },
      {
        id: 10,
        message: "งานให้ความร้อนฝาฟอยล์",
        path: "/work-applied/cap-heating",
      },
      {
        id: 11,
        message: "งานให้ความร้อนเหล็ก",
        path: "/work-applied/heating-steel",
      },
      {
        id: 12,
        message: "งานให้ความร้อนชิ้นส่วนรถยนต์",
        path: "/work-applied/auto-part",
      },
    ],
  },
  { id: 4, header: "ติดต่อเรา", path: "/contact-us", details: [] },
];

export const setDataOfMobile = [
  { id: 1, header: "หน้าหลัก", path: "/home", details: [] },
  {
    id: 2,
    header: "จัดจำหน่ายเครื่อง Induction",
    path: "/product/induction",
    details: [],
  },
  {
    id: 3,
    header: "ขายอุปกรณ์ไฟฟ้า/อิเล็คทรอนิคส์",
    path: "/product/electronics",
    details: [],
  },
  { id: 4, header: "งานบริการ", path: "/product/service", details: [] },
  { id: 5, header: "ติดต่อเรา", path: "/contact-us", details: [] },
];

export const groupOneImagesTypeOfWork = [
  {
    id: 1,
    url: "images/bbn-bg.jpg",
    wording: "การหลอมโลหะ",
    describe: "การหลอมโลหะ (Induction Melting)",
  },
  {
    id: 2,
    url: "images/bg-image-main.jpeg",
    wording: "การชุบแข็งโลหะ",
    describe: "การชุบแข็งโลหะ (Induction Hardening)",
  },
  {
    id: 3,
    url: "images/bg-image-main.jpeg",
    wording: "การอบอ่อนโลหะ",
    describe: "การอบอ่อนโลหะ (Induction Annealing)",
  },
  {
    id: 4,
    url: "images/bg-image-main.jpeg",
    wording: "Induction Carbide",
    describe: "Induction Carbide (Induction Carbide)",
  },
  {
    id: 5,
    url: "images/bbn-bg.jpg",
    wording: "การหลอมพลาสติก",
    describe: "การหลอมพลาสติก (Induction Extruder)",
  },
  {
    id: 6,
    url: "images/bg-image-main.jpeg",
    wording: "Induction Cap Heating",
    describe: "Induction Cap Heating (Induction Cap Heating)",
  },
];

export const groupTwoImagesTypeOfWork = [
  {
    id: 1,
    url: "images/bbn-bg.jpg",
    wording: "การสวมเพลา",
    describe: "การสวมเพลา (Induction Shrink Fitting)",
  },
  {
    id: 2,
    url: "images/bg-image-main.jpeg",
    wording: "การหยดเม็ดโลหะ",
    describe: "การหยดเม็ดโลหะ (Induction Granulating)",
  },
  {
    id: 3,
    url: "images/bg-image-main.jpeg",
    wording: "Induction Heating Steel Bar",
    describe: "Induction Heating Steel Bar (Induction Heating Steel Bar)",
  },
  {
    id: 4,
    url: "images/bg-image-main.jpeg",
    wording: "การหล่อโลหะ",
    describe: "การหล่อโลหะ (Induction Casting)",
  },
  {
    id: 5,
    url: "images/bbn-bg.jpg",
    wording: "การขึ้นรูปโลหะ",
    describe: "การขึ้นรูปโลหะ (Induction Forging)",
  },
  {
    id: 6,
    url: "images/bg-image-main.jpeg",
    wording: "Induction Auto-Parts",
    describe: "Induction Auto-Parts (Induction Auto-Parts)",
  },
];
