import React from "react";
import SlideImage from "../../fragments/js/SlideImage";
import "../css/Home.scss";
import Footer from "../../fragments/js/Footer";
import ListOfCards from "../../fragments/js/ListOfCards";
import { useSelector } from "react-redux";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import { imagesSlideBar, imageWorkApply } from "../../../data/home.model";

export default function Home() {
  const appReducer = useSelector(({ appReducer }) => appReducer);
  const setImages = imagesSlideBar;
  const row = 2;
  const setImagesWorkApply = (row) => {
    let setItem = [];
    let index = 1;
    imageWorkApply.map((data) => {
      setItem[index] === undefined
        ? (setItem[index] = [data])
        : setItem[index].push(data);
      index = index + 1;
      index = index == row ? 0 : index;
    });
    return setItem;
  };
  const listOfImages = setImagesWorkApply(row);
  return (
    <div className="home-body">
      <SlideImage images={setImages} />
      <div
        className={
          appReducer.isMobile ? "home-bbn-services-mobile" : "home-bbn-services"
        }
      >
        <img src="/images/background/bbn-bg.jpg" />
        <div className="content">
          <div className="header">Welcome to BBN ENGINEERING</div>
          <div className="describe">
            ผลิตและจำหน่ายงานด้าน INDUCTION HEAT
            ที่ใช้สำหรับงานหล่อ,งานหลอม,งานชุบแข็ง,งานคลายเครียดโลหะ
          </div>
          <div className="list-services">
            <div className="header">งานที่เกี่ยวข้อง :</div>
            <ul>
              <li>งานวิศวกรรมไฟฟ้า-อิเล็กทรอนิกส์</li>
              <li>
                การจัดซ่อมเครื่องจักรที่ใช้ในงานอุตสาหกรรม
                ,การปรับปรุงเครื่องจักร (REFURBISH)
              </li>
              <li>ตู้ MDB และตู้สวิทซ์บอร์ด (main distribution board)</li>
              <li>ระบบ AUTOMATION</li>
              <li>ให้คำปรึกษา โดยทีมงานวิศวกรที่มากด้วยความรู้และประสบการณ์</li>
            </ul>
          </div>
        </div>
      </div>
      <ListOfCards listdata={listOfImages} hasContent={true} />
      <div className="home-ads">
        <div className="content">
          <div className="content-header">
            การเหนี่ยวนำความร้อนคืออะไรและทำงานอย่างไร?
          </div>
          <div className="content-body">
            การให้ความร้อนแบบเหนี่ยวนำเป็นวิธีที่รวดเร็ว มีประสิทธิภาพ แม่นยำ
            และทำซ้ำได้ สำหรับการให้ความร้อนกับวัสดุที่เป็นสื่อกระแสไฟฟ้า เช่น
            ทองเหลือง อะลูมิเนียม ทองแดง หรือเหล็ก หรือวัสดุกึ่งตัวนำ เช่น
            ซิลิกอนคาร์ไบด์ คาร์บอน หรือกราไฟต์
            ในการให้ความร้อนกับวัสดุที่ไม่นำไฟฟ้า เช่น พลาสติกหรือแก้ว
            ให้ความร้อนเหนี่ยวนำโดยใช้ตัวรับกราไฟท์ซึ่งจะถ่ายเทความร้อนไปยังวัสดุที่ไม่นำไฟฟ้า
          </div>
          <div className="content-body padding-bottom-incress-30">
            การทำความร้อนแบบเหนี่ยวนำถูกนำมาใช้อย่างมีประสิทธิภาพในหลายกระบวนการ
            เช่น การหลอมโลหะ งานชุบแข็ง งานขึ้นรูปน็อต งานจิวเวลรี่
            งานอุตสหกรรมเหล็ก อุตสาหกรรมยานยนต์ อุปกรณ์ทางการแพทย์ และการบิน
            ใช้ความร้อนเหนี่ยวนำในกระบวนการอย่างมีประสิทธิภาพ
          </div>
        </div>
      </div>
      <div className="home-ads-second">
        <div className="content">
          <div className="row">
            <h1 className="col-sm">
              การออกแบบระบบให้ความร้อนสำหรับงานอุตสาหกรรม
            </h1>
          </div>
          <div className={appReducer.isMobile ? "col mobile" : "row"}>
            {/* Column1 */}
            <div className="col">
              <div className="logo">
                <LocalFireDepartmentIcon fontSize="inherit" />
              </div>
              <h5>ให้ความร้อนเร็วลดการสูญเสียความร้อน</h5>
              <h6>
                ออกแบบชุดขดลวดให้ความร้อน
                ให้เหมาะสมกับโหลดและลดการสูญเสียความร้อน.
              </h6>
            </div>
            {/* Column2 */}
            <div className="col">
              <div className="logo">
                <BatteryChargingFullIcon fontSize="inherit" />
              </div>
              <h5>ลดการสูญเสียกำลังไฟฟ้า</h5>
              <h6>ออกแบบภาคจ่ายกำลังเพื่อลดการสูญเสียกำลังทางไฟฟ้า.</h6>
            </div>
            {/* Column3 */}
            <div className="col">
              <div className="logo">
                <SportsEsportsIcon fontSize="inherit" />
              </div>
              <h5>ควบคุมกำเนิดความถี่แบบแยกส่วน</h5>
              <h6>
                เพื่อความต้องการความร้อนแยกส่วนที่จำเป็น
                สามารถควบคุมความร้อนที่แยกชุดกัน.
              </h6>
            </div>
            {/* Column4 */}
            <div className="col">
              <div className="logo">
                <ThermostatIcon fontSize="inherit" />
              </div>
              <h5>รองรับการควบคุมอุณหภูมิที่ง่ายขึ้น</h5>
              <h6>
                สามารถสั่งการทำงานจากอุปกรณ์ภายนอกจากTemp. Controller หรือ PLC
                เป็นสัญญาณอนาล็อก 0-10V หรือ 0-20mA.
              </h6>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
