import {
  setDataOfMobile,
  setDataOfDesktop,
} from "../../../data/induction-work.model";
import { Dehaze, Close } from "@mui/icons-material/";
import "../css/Navbar.scss";
import DropdownMenu from "./DropdownMenu";
import NavItems from "./NavItems";
import { useSelector, useDispatch } from "react-redux";
import * as navbarAction from "../../../actions/navbar.action";
import { Link } from "react-router-dom";

function Navbar() {
  const appReducer = useSelector(({ appReducer }) => appReducer);
  const navbarReducer = useSelector(({ navbarReducer }) => navbarReducer);
  const dispatch = useDispatch();

  const setNavItemsForDesktop = setDataOfDesktop.map((data) => (
    <NavItems key={data.id} wording={data.header} goTo={data.path}>
      {data.details.length !== 0 && <DropdownMenu setData={data.details} />}
    </NavItems>
  ));
  const setNavItemsForMobile = setDataOfMobile.map((data) => (
    <NavItems key={data.id} wording={data.header} goTo={data.path} />
  ));

  return (
    <nav className="bbn-navbar">
      <div
        className="bbn-navbar-icon"
        onClick={() => dispatch(navbarAction.handleClick())}
      >
        {navbarReducer.menuToggle ? (
          <Close fontSize="large" />
        ) : (
          <Dehaze fontSize="large" />
        )}
      </div>
      <Link className="bbn-logo-home" to="/home">
        <img src="/images/logo/bheat-logo.png" />
      </Link>
      {appReducer.isMobile ? (
        <ul
          className={
            navbarReducer.menuToggle
              ? "bbn-navbar-nav-mobile active"
              : "bbn-navbar-nav-mobile"
          }
        >
          {setNavItemsForMobile}
        </ul>
      ) : (
        <ul className="bbn-navbar-nav">{setNavItemsForDesktop}</ul>
      )}
    </nav>
  );
}

export default Navbar;
