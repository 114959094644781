import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import * as appAction from "../../../actions/app.action";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal({ title, message }) {
  const appReducer = useSelector(({ appReducer }) => appReducer);
  const dispatch = useDispatch();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={appReducer.isShowModal}
      onClose={() => dispatch(appAction.handleToggleModal(false))}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={appReducer.isShowModal}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography
            id="transition-modal-description"
            style={{ marginBottom: "10px" }}
            sx={{ mt: 2 }}
          >
            {message}
          </Typography>
          <Button
            variant="contained"
            size="medium"
            onClick={() => dispatch(appAction.setToggleModalState(false))}
          >
            ปิด
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
}
