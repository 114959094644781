export const machineDesign = [
  {
    id: 1,
    url: "/images/pages/product-services/machine-1.jpg",
    path: "",
  },
  {
    id: 2,
    url: "/images/pages/product-services/machine-2.jpg",
    path: "",
  },
  {
    id: 3,
    url: "/images/pages/product-services/machine-3.jpg",
    path: "",
  },
  {
    id: 4,
    url: "/images/pages/product-services/machine-5.jpg",
    path: "",
  },
  {
    id: 5,
    url: "/images/pages/product-services/machine-6.jpg",
    path: "",
  },
  {
    id: 6,
    url: "/images/pages/product-services/machine-7.jpg",
    path: "",
  },
  {
    id: 7,
    url: "/images/pages/product-services/machine-9.jpg",
    path: "",
  },
  {
    id: 8,
    url: "/images/pages/product-services/machine-10.jpg",
    path: "",
  },
  {
    id: 9,
    url: "/images/pages/product-services/machine-11.jpg",
    path: "",
  },
  {
    id: 10,
    url: "/images/pages/product-services/machine-12.jpg",
    path: "",
  },
];

export const electronicDesign = [
  {
    id: 1,
    url: "/images/pages/product-services/electronic-1.jpg",
    path: "",
  },
  {
    id: 2,
    url: "/images/pages/product-services/electronic-2.jpg",
    path: "",
  },
  {
    id: 3,
    url: "/images/pages/product-services/electronic-3.jpg",
    path: "",
  },
  {
    id: 4,
    url: "/images/pages/product-services/electronic-4.jpg",
    path: "",
  },
  {
    id: 5,
    url: "/images/pages/product-services/electronic-5.jpg",
    path: "",
  },
  {
    id: 6,
    url: "/images/pages/product-services/electronic-6.jpg",
    path: "",
  },
  {
    id: 7,
    url: "/images/pages/product-services/electronic-7.jpg",
    path: "",
  },
  {
    id: 8,
    url: "/images/pages/product-services/electronic-8.jpg",
    path: "",
  },
];

export const coilDesign = [
  {
    id: 1,
    url: "/images/pages/product-services/coil-1.jpg",
    path: "",
  },
  {
    id: 2,
    url: "/images/pages/product-services/coil-2.jpg",
    path: "",
  },
  {
    id: 3,
    url: "/images/pages/product-services/coil-3.jpg",
    path: "",
  },
  {
    id: 4,
    url: "/images/pages/product-services/coil-4.jpg",
    path: "",
  },
  {
    id: 5,
    url: "/images/pages/product-services/coil-5.jpg",
    path: "",
  },
];
