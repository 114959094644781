import "../css/DropdownMenu.scss";
import { Link, Route } from "react-router-dom";

function DropdownMenu(props) {
  return (
    <div className="dropdown">
      {props.setData.map((data, index) => {
        return (
          <Link className="bbn-menu-items" key={index} to={data.path}>
            {data.message}
          </Link>
        );
      })}
    </div>
  );
}

export default DropdownMenu;
