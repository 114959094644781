import { NAVBAR_ACTION_MENU_TOGGLE } from "../Constant";

const initialState = {
  menuToggle: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NAVBAR_ACTION_MENU_TOGGLE:
      return { ...state, menuToggle: !state.menuToggle };
    default:
      return state;
  }
};
