import React from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import Home from "./components/pages/js/Home";
import ContactUs from "./components/pages/js/ContactUs";
import ProductGrid from "./components/pages/js/ProductGrid";
import { useTransition, animated } from "react-spring";
import ServicePage from "./components/pages/js/ServicePage";
import WorkAppliedPage from "./components/pages/js/WorkAppliedPage";
import { dataWorkApplied } from "./data/work-applied.model";

export default function PublicRouter() {
  const location = useLocation();
  return (
    <Switch location={location}>
      <Route exact={true} path="/" component={() => <Redirect to="/home" />} />
      <Route path="/home" component={Home} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/product/induction" component={ContactUs} />
      <Route path="/product/electronics" component={ProductGrid} />
      <Route path="/product/service" component={ServicePage} />
      <Route
        path={[
          "/work-applied/melting",
          "/work-applied/hardening",
          "/work-applied/annealing",
          "/work-applied/extruder",
          "/work-applied/cap-heating",
          "/work-applied/forging",
          "/work-applied/carbide",
          "/work-applied/shrink-fitting",
          "/work-applied/heating-steel",
          "/work-applied/granulating",
          "/work-applied/casting",
          "/work-applied/auto-part",
        ]}
        component={WorkAppliedPage}
      />
    </Switch>
  );
}
