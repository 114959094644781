import { Container } from "@mui/material";
import React from "react";
import "../css/Footer.scss";

export default function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          {/* Column1 */}
          <div className="col">
            <h4>บริษัท บีบีเอ็น เอ็นจิเนียริ่ง จำกัด</h4>
            <h5 className="list-unstyled">
              <li>39/46 ซอยเพชรเกษม 110</li>
              <li>แขวงหนองค้างพลู เขตหนองแขม</li>
              <li>กรุงเทพฯ 10160</li>
            </h5>
          </div>
          {/* Column2 */}
          <div className="col">
            <h4>Contact</h4>
            <ui className="list-unstyled">
              <li>Facebook : Induction Heater BBN Engineering</li>
              <li>Line : @041ykdwm</li>
              <li>Tel : 081-811-8923, 092-276-4619</li>
            </ui>
          </div>
        </div>
        <hr />
        <div className="row">
          <p className="col-sm">
            &copy;{new Date().getFullYear()} BBN Engineering | All rights
            reserved | Terms Of Service | Privacy
          </p>
        </div>
      </div>
    </div>
  );
}
